.banner {
    position: relative;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: top;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-top: -140px;
    padding-top: 36%;
}

.user {
    align-items: flex-end;
    bottom: -70px;
    display: flex;
    left: 40px;
    position: absolute;
    z-index: 1;
}

.user-avatar {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    height: 172px;
    justify-content: center;
    width: 172px;
}

.user-name {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 16px;
    margin-left: 16px;
    text-align: center;
}

.container {
    margin-top: 100px;
}

.info {
    display: flex;
    flex-direction: column;

    div {
        align-items: flex-start;
        display: flex;
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-top: 15px;
    }

    .info-icon {
        color: #808990;
        margin-right: 8px;
        margin-top: 2px;
        text-align: center;
        width: 20px;
    }
}

.highlight {
    font-weight: 600;
}

.inner {
    align-items: flex-start;
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid #d0d7de;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
}

.thumb {
    flex-shrink: 0;
    height: 128px;
    margin-right: 24px;
    width: 228px;
}
.img {
    border-radius: 16px;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.info-title {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
}

.info-title a {
    color: var(--text-color);
}

.info-desc {
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-top: 4px;
    overflow: hidden;
}

// .profile_info-analytics {
//     margin-top: 10px;
//     font-size: 1.6rem;
//     font-weight: 300;
//
//     .value ~ .value {
//         margin-left: 10px;
//     }
//
//     .icon {
//         color: #161823bf;
//     }
// }
