.wrapper {
    p {
        font-size: 1.6rem;
    }
}

.editor {
    padding-left: 0 !important;
    font-size: 1.6rem !important;
    line-height: 1.8 !important;
}
