.wrapper {
    overflow: hidden;
    border-radius: 4px;
    height: 100%;
}

.player {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: var(--black-color);
}

.video-steam {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}

.shadow {
    color: var(--white-color);
    position: absolute;
    font-size: 1.8rem;
    top: 40%;
    left: 46%;
    transform: translate(-50%, -50%) scale(0.2);
    padding: 12px 20px;
    border-radius: 50%;
    background-color: #0000009e;
    animation: animPlay 1.4s ease;
    transition: all 1.4s ease-in-out;
    will-change: opacity, transform;
}

@keyframes animPlay {
    0% {
        opacity: 0.8;
        transform: scale(0.2);
    }
    80% {
        opacity: 0;
        transform: scale(1.4);
    }
    100% {
        opacity: 0;
        transform: scale(2.4);
    }
}
