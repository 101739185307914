.wrapper {
    min-height: 270px;
}

.item {
    display: flex;
    border-radius: 16px;
    width: 100%;
    height: 270px;
    margin: 0 0.3px;
    overflow: hidden;
}

.left {
    color: var(--white-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    font-size: 1.6rem;
    line-height: 1.6;
    max-width: 100%;
    padding: 0 36px 36px;
    width: 640px;
    overflow: hidden;
}

.heading {
    a {
        color: var(--white-color);
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 1.5;
    }
}

.desc {
    margin-bottom: 24px;
    margin-top: 0;
    max-width: 600px;
}

.ctaBtn {
    font-size: 1.4rem;

    &:hover {
        background-color: #fff;
        color: var(--cta-hover-color);
    }
}

.right {
    display: flex;
    flex: 3 1;
    justify-content: flex-end;
}

.img {
    height: 100%;
    object-fit: cover;
}

// Arrows \
.arrow {
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    color: #4b4b4b;
    font-size: 1.2rem;
    height: 32px;
    line-height: 32px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 32px;
    cursor: pointer;
    z-index: 1;
}

.next {
    right: 0;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
}

.prev {
    left: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
