.wrapper {
    align-items: center;
    background-color: #fff;
    bottom: 0;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    justify-content: center;
    left: 0;
    line-height: 1.8;
    position: fixed;
    right: 0;
    text-align: center;
    top: -10%;

    p {
        margin-bottom: 32px;
        max-width: 100%;
        width: 680px;
    }
}

.reloadBtn {
    background-color: var(--button-color);
    background-image: var(--button-image);
    padding: 12px 20px;
    color: var(--white-color);
}
