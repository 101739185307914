.wrapper {
    .heading {
        margin-left: 15%;
        font-size: 3.2rem;
    }
}

.body {
    display: flex;
    justify-content: center;
}

.list {
    max-width: 100%;
    width: min(60%, 100vw);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topic {
    h3 {
        color: #757575;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.8;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.4rem;
        padding-left: 0;

        a {
            background-color: #f2f2f2;
            border-radius: 30px;
            color: #333;
            display: block;
            font-weight: 500;
            line-height: 1.8;
            margin: 0 8px 8px 0;
            padding: 6px 16px;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }
}
