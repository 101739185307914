.wrapper {
    display: flex;
    align-items: flex-start;
    padding: 20px 0 8px;
    position: sticky;
    top: -20px;
    background-color: var(--white-color);
    margin-bottom: 50px;
}

.avatarWrap {
    margin-right: 20px;
}

.commentContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.input {
    width: 100%;
    font-size: 1.6rem;
    padding: 10px 0;
    border-bottom: 1px solid var(--text-color);
    margin-bottom: 10px;
}

.btn {
    & + .btn {
        margin-left: 10px;
    }
}
