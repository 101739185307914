.wrapper {
    margin-bottom: 60px;
    padding: 8px 44px 0;
}

.body {
    margin-top: 10px;
    margin-left: -12px;
}

.learningPath {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
}
