.wrapper {
    line-height: 1.6;
    padding: 0 44px 60px;
}

.markdownParser {
    img {
        margin-top: 10px;
    }
}

.aside {
    position: sticky;
    top: 116px;
}

.info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    a + a {
        margin-left: 12px;
    }

    span {
        color: #292929;
        font-size: 1.4rem;
        font-weight: 600;
    }
}

.user {
    display: flex;
    align-items: center;
}

.createdAt {
    font-size: 1.2rem;
    margin-left: 10px;
}

.actions {
    display: flex;
    align-items: center;
}

.optionBtn {
    padding: 4px 8px;
    font-size: 1.8rem;
    cursor: pointer;

    &:hover {
        color: var(--primary-color);
    }

    & + .optionBtn {
        margin-left: 10px;
    }
}

.reaction {
    margin-top: 24px;
}

.topic {
    margin: 16px 0 8px;

    h3 {
        color: #757575;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.8;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.4rem;
        padding-left: 0;

        a {
            background-color: #f2f2f2;
            border-radius: 30px;
            color: #333;
            display: block;
            font-weight: 500;
            line-height: 1.8;
            margin: 0 8px 8px 0;
            padding: 6px 16px;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }
}
