.wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;
    min-width: 100px;
    font-weight: 500;
    font-size: 1.6rem;
    font-family: var(--font-family);
    background-color: var(--white-color);
    border-radius: 8px;
    border: 2px solid transparent;
    cursor: pointer;
    user-select: none;
}

// .wrapper + .wrapper {
//     margin-left: 10px;
// }

.icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
}

.icon + .title,
.title + .icon {
    margin-left: 8px;
}

.primary {
    background-color: var(--primary-color);
    color: var(--white-color);

    &:hover {
        opacity: 0.8;
    }
}

.text {
    &:hover {
        text-decoration: underline;
    }
}

.outline {
    color: currentColor;
    border-color: currentColor;
    background-color: transparent;
}

.rounded {
    border-radius: 20px;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
}

// btn field

.fieldBtn {
    color: rgba(0, 0, 0, 0.54);
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 1.4rem;
    padding: 0 16px;
    height: 37px;
    line-height: 37px;
    min-width: fit-content;

    & + .fieldBtn {
        margin-left: 8px;
    }

    &:hover {
        border-color: rgba(0, 0, 0, 0.54);
        color: rgba(0, 0, 0, 0.68);
    }
}

.saveFieldBtn {
    color: var(--primary-color);
    border-color: var(--primary-color);
}
