.wrapper {
    background-color: rgba(221, 228, 236, 0.301);
    padding: 20px;
    margin: 10px 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info {
    display: flex;
    align-items: center;
}

.check {
    margin-right: 10px;
    width: 16px;
    height: 16px;
}

.icon {
    font-size: 2.4rem;
    margin-right: 10px;
}

.title {
    color: #797979;
}

.remove-btn {
    font-size: 1.4rem;
    background-color: transparent;
}
