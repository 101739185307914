.wrapper {
    background-color: #578572;
    padding-top: 50px;

    .logo-link {
        color: var(--white-color);
        font-size: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            font-size: 4rem;
            margin-bottom: 6px;
        }
    }
}

.heading {
    font-size: 1.8rem;
    text-transform: uppercase;
    color: var(--white-color);
    margin-bottom: 20px;
}

.list {
    padding: 0;
    list-style: none;
}

.item-link {
    text-decoration: none;
    font-size: 1.4rem;
    color: var(--white-color);
    padding: 4px 0;
    display: flex;
    align-items: center;
}

.item-link:hover {
    color: var(--primary-color);
}

.item-icon {
    font-size: 1.6rem;
    margin: -1px 8px 0 0;
}

.bottom {
    padding: 12px 0;
    margin-top: 36px;
}

.text {
    font-size: 1.4rem;
    color: var(--white-color);
    margin: 0;
    text-align: center;
}
