.wrapper {
    width: 100%;
    // max-height: 486px;
}

.img {
    border-radius: 4px;
    width: 100%;
    object-fit: cover;
}
