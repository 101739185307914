.wrapper {
    margin-top: 20px;
    padding: 0 40px 60px;
}

.courseName {
    font-size: 3.2rem;
    margin-top: 4px;
}

.courseDesc {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.4rem;
    line-height: 1.6;
}

.purchaseBadge {
    width: 100%;
    position: sticky;
    top: 90px;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h5 {
        color: var(--primary-color);
        font-size: 32px;
        font-weight: 400;
        margin: 0 auto;
        opacity: 0.8;
    }

    ul {
        display: inline-block;
        margin: 0;
        padding: 24px 0 10px 4px;
        // text-align: left;

        li {
            color: #494949;
            font-size: 1.4rem;
            line-height: 1.6;
            margin-bottom: 10px;
            padding-left: 35px;
            position: relative;

            svg {
                margin-right: 10px;
            }
        }
    }
}

// Preview
.preview {
    border-radius: 16px;
    margin: 2px 0 20px;
    overflow: hidden;
    position: relative;
    user-select: none;
    width: calc(100% - 2px);
    margin-left: 24px;

    &:after {
        background-image: linear-gradient(180deg, rgba(30, 30, 28, 0), rgba(30, 30, 28, 0.9));
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: all 0.3s linear;
    }

    p {
        position: absolute;
        bottom: 16px;
        left: 0;
        right: 0;
        color: var(--white-color);
        font-size: 1.6rem;
        font-weight: 500;
        text-align: center;
        width: 100%;
        z-index: 1;
    }

    .icon {
        color: var(--white-color);
        font-size: 6rem;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s linear;
        cursor: pointer;
        z-index: 1;
    }
}

.bg {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: 56.25%;
    width: 100%;
}

.learnNow {
    border-radius: 20px;
    font-size: 1.6rem;
    margin-top: 16px;
    min-width: 180px;
    padding: 10px 16px;
    text-transform: uppercase;
}

// Topic list
.topicList {
    .topicHeading {
        font-size: 2rem;
        font-weight: 700;
        margin: 36px 0 12px;
    }
}

.list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-left: 0;

    &.column {
        flex-direction: column;
    }

    li {
        color: var(--text-color);
        flex-basis: 50%;
        font-size: 1.4rem;
        line-height: 1.6;
        margin-bottom: 12px;
        padding: 0 24px;
        position: relative;

        .icon {
            color: var(--primary-color);
            font-size: 1.4rem;
            left: 0;
            position: absolute;
            top: 2px;
        }
    }
}
