.wrapper {
    width: 100%;
    margin: 20px;
    border-bottom: 1px solid rgb(219, 219, 219);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.author {
    display: flex;
    align-items: center;

    a + a {
        margin-left: 12px;
    }

    span {
        color: #292929;
        font-size: 1.4rem;
        font-weight: 600;
    }
}

.createdAt {
    font-size: 1.2rem;
    margin-left: 10px;
}

.actions {
    display: flex;
    align-items: center;
}

.optionBtn {
    padding: 4px 8px;
    font-size: 1.8rem;
    cursor: pointer;

    &:hover {
        color: var(--primary-color);
    }

    & + .optionBtn {
        margin-left: 10px;
    }
}

.body {
    width: 100%;
    margin-bottom: 8px;
}

.info {
    margin-bottom: 10px;
}

.title {
    color: #292929;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.6;
    margin-top: 8px;
}

.description {
    color: #505050;
    margin-top: 4px;
    font-size: 1.6rem;
    line-height: 1.8rem;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
}

.hashtag {
    font-size: 1.4rem;
    font-weight: 400;
    color: rgb(55, 55, 255);
}

.presentation {
    width: 100%;
    max-height: 486px;
    overflow: hidden;
    margin-bottom: 6px;
}

.img {
    width: 100%;
    object-fit: contain;
    border-radius: 4px;
}

// footer
.footer {
    padding-bottom: 16px;
}
// .reactions {
//     display: flex;
//     align-items: center;
// }
