.wrapper {
    display: flex;
    justify-content: space-between;
}

.label {
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 10px 0 10px 8px;
}
