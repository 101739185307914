.wrapper {
    width: 300px;
    overflow: visible;
}

.content {
    max-height: 68vh;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-bottom: 8px;
}

// Inbox items
.item {
    display: flex;
    align-items: center;
    padding: 10px;
    transition: background-color 0.3s;
    border-radius: 10px;
    margin: 0 8px;
    cursor: pointer;

    &:hover {
        background-color: rgba(22, 24, 35, 0.05);
    }

    &:hover .link {
        color: var(--black-color);
    }
}
