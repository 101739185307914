.wrapper {
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
}

.content {
    flex: 1;
    width: calc(100% - 96px);
    padding: 0 40px 0 20px;
}

.sidebar {
    flex-shrink: 0;
}
