.wrapper {
    flex: 1;

    .inner {
        margin: 0 auto 100px;
        max-width: 90%;
        width: 720px;
    }
}

.content {
    margin-top: 36px;
}

.answer {
    background-color: #f6f7f9;
    border: 2px solid transparent;
    border-radius: 8px;
    box-shadow: 8px 0 8px rgba(137, 172, 192, 0.04);
    font-size: 1.6rem;
    padding: 16px;
    text-align: center;
    transition: 0.2s ease;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    + .answer {
        margin-top: 16px;
    }

    &.selected {
        border-color: #0093fc;
    }

    &.error {
        background-color: #ffeaea;
        border-color: #cc5140;
    }

    &.success {
        background-color: #f0ffed;
        border-color: #48bd79;
    }
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}

.submit {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.explanation {
    h2 {
        font-size: 1.8rem;
    }
}
