.wrapper {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    &.closing .content {
        animation-name: inlineOut;
    }
}

.overlay {
    animation-duration: 0.5s;
    animation-name: inline;
    animation-timing-function: linear;
    background: rgba(0, 0, 0, 0.4);
    inset: 0;
    position: absolute;
}

.content {
    animation-duration: 0.5s;
    animation-name: inlineIn;
    background: #fff;
    border-radius: 16px;
    height: min(660px, 100% - 32px);
    overflow: hidden;
    position: relative;
    width: min(540px, 100% - 32px);
    z-index: 1;
}

.close {
    align-items: center;
    background: rgba(22, 24, 35, 0.03);
    border-radius: 50%;
    color: #333;
    cursor: pointer;
    display: flex;
    font-size: 2.4rem;
    height: 40px;
    justify-content: center;
    line-height: 0;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 40px;
    z-index: 1;
}

.iframe {
    border: none;
    height: 100%;
    width: 100%;
}

@keyframes inline {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes inlineIn {
    0% {
        opacity: 0;
        scale: 0;
    }

    to {
        opacity: 1;
        scale: 1;
    }
}

@keyframes inlineOut {
    0% {
        opacity: 1;
        scale: 1;
    }
    to {
        opacity: 0;
        scale: 0;
    }
}
