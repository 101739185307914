.wrapper {
    .heading {
        font-size: 2.8rem;
        margin: 16px 0 8px;
    }

    .updated {
        font-size: 1.3rem;
        margin-bottom: 24px;
    }
}

.desc {
    font-size: 1.2rem;
    line-height: 2;
    word-wrap: break-word;
    max-width: 840px;
}
