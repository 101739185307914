.wrapper {
    margin-top: 24px;
    padding: 0 32px;
}

.title-input {
    border: none;
    color: #292929;
    font-size: 3.2rem;
    font-weight: 500;
    min-height: 48px;
    width: 100%;
}

.btn {
    float: right;
}
