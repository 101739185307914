.wrapper {
    display: flex;
    gap: 80px;
    margin: 0 120px;
    min-height: 100vh;
}

.sidebar {
    width: 24%;

    li {
        border-radius: 8px;

        a {
            align-items: center;
            border-radius: 8px;
            display: flex;
            gap: 8px;
            margin-bottom: 4px;
            padding: 14px 12px;

            &.active {
                background-color: #eee;
                border-radius: 8px;
            }
        }

        .icon {
            color: #fb8500;
            font-size: 2rem;
            width: 20px;
        }

        span {
            color: var(--text-color);
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 2rem;
        }
    }
}

.heading {
    color: var(--text-color);
    font-size: 3rem;
    font-weight: 600;
    margin: 20px 0;
}

.sidebar-list {
    min-width: 270px;
    padding-left: 0;
    margin: 10px 0;
}

.page {
    flex: 1;
    margin-top: 54px;
    padding-bottom: 48px;
}
