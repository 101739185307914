.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
    color: #8292a2;
}

.token.punctuation {
    color: #f8f8f2;
}

.token.namespace {
    opacity: 0.7;
}

.token.constant,
.token.deleted,
.token.property,
.token.symbol,
.token.tag {
    color: #f92672;
}

.token.boolean,
.token.number {
    color: #ae81ff;
}

.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
    color: #a6e22e;
}

.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url,
.token.variable {
    color: #f8f8f2;
}

.token.atrule,
.token.attr-value,
.token.class-name,
.token.function {
    color: #e6db74;
}

.token.keyword {
    color: #66d9ef;
}

.token.important,
.token.regex {
    color: #fd971f;
}

.token.bold,
.token.important {
    font-weight: 700;
}

.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}
