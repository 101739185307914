.wrapper {
    .title {
        color: #242424;
        font-size: 2.4rem;
        font-weight: 900;
        margin: 20px 0;
    }
}

.desc {
    margin: 10px 0;
    font-size: 1.4rem;
    line-height: 1.6;
}
