.wrapper {
    padding: 20px 0;
    flex: 0 0 31%;
    max-width: 31%;
}

.container {
    width: 100%;
    border: 2px solid #ffcda9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 480px;
}

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.heading {
    font-size: 1.6rem;
    line-height: 1.4;
    margin: 0;
}

.btnClose {
    background-color: transparent;
    font-size: 1.8rem;
    cursor: pointer;

    &:hover {
        color: var(--primary-color);
    }
}

.body {
    flex: 1;
    list-style: none;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-bottom: 8px;
}

// trackItem
.item {
    padding: 10px;
    transition: background-color 0.3s;
    border-radius: 10px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: rgba(22, 24, 35, 0.05);
    }

    &.active {
        background-color: #ffcda9;
    }

    &.disabled {
        background-color: #e7e7e7;
        opacity: 0.6;
        pointer-events: none;
    }
}

.index {
    margin-right: 10px;
}

.info {
    text-decoration: none;
    color: var(--black-color);
    display: flex;
    align-items: center;
}

.thumb {
    background-color: #ebebeb;
    border-radius: 8px;
    display: block;
    line-height: 67px;
    max-height: 67px;
    text-align: center;
    width: 100px;
}
.title {
    // padding: 4px;
    margin-left: 6px;
    font-size: 1.6rem;
    line-height: 1.8rem;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
}
