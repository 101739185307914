.wrapper {
    -webkit-animation: FadeIn 0.5s ease;
    animation: FadeIn 0.5s ease;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    will-change: opacity;
    z-index: 10;
}

.container {
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    max-width: 100%;
    min-width: 720px;
    position: relative;
    width: 43%;
    will-change: transform;
    animation: SlideIn 0.5s ease;
    cursor: default;
}

.closing {
    animation: FadeOut 0.5s ease forwards;

    .container {
        animation: SlideOut 0.5s ease forwards;
    }
}

@keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes FadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes SlideIn {
    0% {
        -webkit-transform: translateX(80%);
        transform: translateX(80%);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes SlideOut {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(80%);
        transform: translateX(80%);
    }
}
