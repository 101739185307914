.wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.label {
    font-size: 1.6rem;
    margin-bottom: 16px;
    font-weight: 500;
}

.control {
    font-size: 1.4rem;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 8px;
    padding-bottom: 8px;
}

.desc {
    color: #757575;
    font-size: 1.4rem;
    line-height: 2rem;
    margin: 14px 0;
}
