.wrapper {
    color: #666;
    font-size: 1.4rem;
    padding: 12px 0;
    text-align: center;
    margin: 14px 0;

    .heart {
        color: #d43c68;
    }
}
